import { RDSButton, RDSInputField } from '@reconlabs/reconlabs-fe-components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { loadPaymentWindow } from '../utils/payment';
import { AxiosInstance } from '../utils/axios';

const Main = () => {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const [amount, setAmount] = useState('0원');
  const [description, setDescription] = useState('제품 상세 내용');
  const customerKey = searchParams.get('customerKey');

  useEffect(() => {
    (async () => {
      const res = await getPaymentInfo();
      if (!res) return;
      const { amount, description } = res;
      setAmount(formatCurrency(amount));
      setDescription(description);
    })();
  }, []);

  const getPaymentInfo = async (): Promise<{ amount: number; description: string } | null> => {
    const res = await AxiosInstance.get('/ready', { params: { customerKey } });
    console.log(res);
    if (!res.data?.payment) return null;
    return { amount: res.data.payment.amount, description: res.data.payment.description };
  };

  const pay = () => {
    customerKey && loadPaymentWindow(customerKey);
  };

  return (
    <div className="Main">
      <div className="Main__main">
        <div className="Main__main__img">
          <img src="/images/logo.png" alt="PlicAR" />
        </div>
        <div className="Main__main__title">{t('Main.제목')}</div>
        <div className="Main__main__description">
          <span>{t('Main.내용.1')}</span>
          <span>{t('Main.내용.2')}</span>
        </div>
        <textarea className="Main__main__descriptionArea" value={description} disabled rows={8} />
        <div className="Main__main__amount">{`${t('Main.월간 정기결제 금액')}`}</div>
        <RDSInputField.Text
          value={amount}
          handleInputChange={() => {}}
          width="100%"
          disabled={true}
          customStyles={{ '.RDSInputField:disabled': { color: '#646466' } }}
        />
        <RDSButton children={`${t('Main.결제하기')}`} fullWidth customStyle={{ marginTop: '20px' }} onClick={pay} />
      </div>
      <div className="Main__info">
        <span>{t('Main.info.1')}</span>
        <span>{t('Main.info.2')}</span>
        <span>{t('Main.info.3')}</span>
        <span>{t('Main.info.4')}</span>
        <span>{t('Main.info.5')}</span>
      </div>
      <div className="Main__footer">
        <a href="https://reconlabs.notion.site/106b051b77c144199b28217f849d8b66">{t('Main.footer.1')}</a>
        <a href="https://reconlabs.notion.site/0f7df0cb2d7942ad822f6f88e254bbfd">{t('Main.footer.2')}</a>
        <a href="https://reconlabs.notion.site/d1b81cbce44148c3bcacba381445c8e1" style={{ fontWeight: 'bold' }}>
          {t('Main.footer.3')}
        </a>
      </div>
    </div>
  );
};

function formatCurrency(amount: number): string {
  const formatter = new Intl.NumberFormat('ko-KR', {
    style: 'currency',
    currency: 'KRW',
  });
  return formatter.format(amount).replace('₩', '') + '원';
}

export default Main;
