import { loadTossPayments } from '@tosspayments/payment-sdk';
import { AxiosInstance } from './axios';

const clientKey = process.env.REACT_APP_TOSS_CLIENT_KEY;
const redirectURL = `${process.env.REACT_APP_RECONLABS_PAYMENT_PROTOCOL}://${process.env.REACT_APP_RECONLABS_PAYMENT_DOMAIN}:${process.env.REACT_APP_RECONLABS_PAYMENT_PORT}`;

//카드 검증 창 띄우기
export const loadPaymentWindow = async (customerKey: string, queryString?: string) => {
  const tossPayments = await loadTossPayments(clientKey as string);
  tossPayments.requestBillingAuth('카드', {
    customerKey,
    successUrl: `${redirectURL}/success?${queryString ?? ''}`,
    failUrl: `${redirectURL}/fail?${queryString ?? ''}`,
  });
};

//자동 결제 등록 및 결제 진행
export const billing = async (customerKey: string, authKey: string) => {
  const res = await AxiosInstance.post(`/billing`, { customerKey, authKey });
  return res;
};
