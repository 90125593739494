import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Fail, Main, Success } from './pages';

function App() {
  return (
    <Routes>
      <Route path="*">
        <Route path="" element={<Main />} />
        <Route path="success" element={<Success />} />
        <Route path="fail" element={<Fail />} />
      </Route>
    </Routes>
  );
}

export default App;
