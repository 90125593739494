import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './en.json';
import ko from './ko.json';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: en,
      },
      ko: {
        translation: ko,
      },
    },
    lng: 'ko', // https://www.i18next.com/overview/configuration-options#languages-namespaces-resources, // https://www.i18next.com/overview/api#changelanguage
    fallbackLng: 'ko',
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
    // detection: {
    //   // 머저 querystring ?lng=LANGUAGE를 검사하고,  브라우저 언어, localStorage 순으로 검사합니다.
    //   order: ['querystring', 'localStorage', 'navigator'],
    //   lookupLocalStorage: 'threedpresso-i18next',
    //   caches: ['localStorage'],
    // },
  });

export default i18n;
