import { RDSModal } from '@reconlabs/reconlabs-fe-components';
import { useTranslation } from 'react-i18next';

type Props = {
  open: boolean;
  onClose: Function;
};
const PaymentFailModal = ({ open, onClose }: Props) => {
  const { t } = useTranslation();

  return (
    <RDSModal
      open={open}
      enableCloseButton={false}
      enableCloseOnBackdropClick={false}
      onClose={onClose}
      title={`${t('Fail.결제 실패 모달 제목')}`}
      supportingText={
        <div>
          <div>{`${t('Fail.결제 실패 모달 문구.1')}`}</div>
          <div>{`${t('Fail.결제 실패 모달 문구.2')}`}</div>
        </div>
      }
      buttonType="noBox"
      button1Label={`${t('Global.닫기')}`}
      button1Fn={() => onClose()}
    ></RDSModal>
  );
};

export default PaymentFailModal;
