import { useState } from 'react';
import PaymentFailModal from '../components/fail/PaymentFailModal';

const Fail = () => {
  const [modalOpen, setModalOpen] = useState(true);

  return <PaymentFailModal open={modalOpen} onClose={() => setModalOpen(false)} />;
};

export default Fail;
