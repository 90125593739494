import { RDSModal } from '@reconlabs/reconlabs-fe-components';
import { useTranslation } from 'react-i18next';

type Props = {
  open: boolean;
  onClose: Function;
};
const PaymentSuccessModal = ({ open, onClose }: Props) => {
  const { t } = useTranslation();

  return (
    <RDSModal
      open={open}
      enableCloseButton={false}
      enableCloseOnBackdropClick={false}
      onClose={onClose}
      imageSrc="/images/payment_complete.png"
      title={`${t('Success.결제 성공 모달 제목')}!`}
      supportingText={`${t('Success.결제 성공 모달 문구')}`}
      buttonType="noBox"
      button1Label={`${t('Global.확인')}`}
      button1Fn={() => onClose()}
    ></RDSModal>
  );
};

export default PaymentSuccessModal;
