import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import PaymentSuccessModal from '../components/success/PaymentSuccessModal';
import PaymentFailModal from '../components/fail/PaymentFailModal';
import { billing } from '../utils/payment';

const Success = () => {
  const [searchParams] = useSearchParams();
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [failModalOpen, setFailModalOpen] = useState(false);

  useEffect(() => {
    (async () => {
      const customerKey = searchParams.get('customerKey');
      const authKey = searchParams.get('authKey');
      console.log('customerKey', customerKey);
      const res = customerKey && authKey && (await billing(customerKey, authKey));
      console.log(res);
      res ? setSuccessModalOpen(true) : setFailModalOpen(true);
    })();
  }, []);

  return (
    <>
      <PaymentSuccessModal open={successModalOpen} onClose={() => setSuccessModalOpen(false)} />
      <PaymentFailModal open={failModalOpen} onClose={() => setFailModalOpen(false)} />
    </>
  );
};

export default Success;
